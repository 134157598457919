<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="div" class="art" id="scroll-block">
      <header class="art__header">
        <h3 class="art__header_title">{{ art.id ? "Edit" : "Add" }} Art</h3>
        <div class="art__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Item"
            @click="$emit('removeArt', art)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid || !changed"
            @click="saveArt"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Required Information</h3>
        <div class="form__row form__row--align-start" style="margin-bottom: 15px">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Artwork Title"
              :required="true"
              :validateError="errors[0]"
              v-model="art.title"
              :element="art.title"
              @input="art.title = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <AutocompleteInput
              :class="{ 'autocomplete-input--invalid': errors[0] }"
              placeholder="Artist"
              :required="true"
              v-model="artistTitle"
              :validateError="errors[0]"
              :loading="artistsLoading"
              :matches="artists"
              :value="art.artist.title"
              @searchMatches="apiGetSearchedArtists({ search: $event })"
              @selectMatch="
                art.artist = $event;
                artistTitle = $event.title;
              "
              @input="artistTitle = $event"
            ></AutocompleteInput>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--align-start">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255, required: true }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="SKU"
              :required="true"
              v-model="art.sku"
              :element="art.sku"
              :validateError="errors[0]"
              @input="art.sku = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <BaseSelect
              placeholder="Artwork Status"
              :validateError="errors[0]"
              :required="true"
              v-model="art.availability"
              type="tag"
              :selected="art.availability"
              :items="saleStatus"
              @input="art.availability = $event.id"
            ></BaseSelect>
          </ValidationProvider>
        </div>
        <ValidationProvider
          tag="div"
          class="form__row form__row_col"
          :rules="{ required: true, path: true, max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Path"
            symbol="/"
            :required="true"
            :validateError="errors[0] || serverError.slug"
            v-model="art.slug"
            :element="art.slug"
            @input="art.slug = $event"
          >
            <template #text>
              <span class="form-control__simple-text">
                Example: {{ `${url}/artworks/${art.slug || "path"}` }}
              </span>
            </template>
          </BaseInput>
        </ValidationProvider>
        <div class="form__row form__row--mb-17 form__row--align-start">
          <div class="form__row_col form__row_col--side form__row_col--flex">
            <SimpleToggle
              placeholder="Page Active"
              v-model="art.status"
              :disabled="invalid || disabled.artStatus"
              :selected="+art.status"
              @input="art.status = Number($event)"
            ></SimpleToggle>
            <SimpleToggle
              placeholder="Featured Artwork"
              :disabled="invalid || art.header_image === '' || !art.status"
              v-model="art.featured"
              :selected="+art.featured"
              @input="art.featured = Number($event)"
            ></SimpleToggle>
          </div>
        </div>
        <div class="form__row form__row--align-start">
          <p v-if="!art.status" class="form__header_notification">
            Please make enabled "Page Active" before using "Featured Artwork" option.
          </p>
          <p v-if="art.status && art.header_image === ''" class="form__header_notification">
            Please add an image to the "Header Image" before enabling the "Featured Artwork" option.
          </p>
          <p v-if="artNotification" class="form__header_notification">
            {{ artNotification }}
          </p>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Attributes</h3>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 65000 }" v-slot="{ errors }">
          <Editor
            placeholder="Description"
            :value="art.description"
            @input="art.description = $event"
            :validateError="errors[0]"
            v-model="art.description"
          ></Editor>
        </ValidationProvider>
        <!-- <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="Description"
            v-model="art.description"
            :element="art.description"
            :validateError="errors[0]"
            @input="art.description = $event"
          ></BaseTextarea>
        </ValidationProvider> -->
        <template v-for="(attribute, id) in art.attributes">
          <h3 class="form__header">
            Piece {{ id + 1 }}
            <IconButton
              v-if="id !== 0"
              class="art__piece-button button--admin button--xsm"
              text="Remove Piece"
              @click="removeArtAttribute(id)"
            >
              <IconClose width="9" height="9"></IconClose>
            </IconButton>
            <IconButton
              v-else
              class="art__piece-button button--admin button--xsm"
              text="Add Piece"
              @click="addArtAttribute"
            >
              <IconPlus width="12" height="12"></IconPlus>
            </IconButton>
          </h3>
          <div class="form__row">
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side form__row_col--side-three"
              :rules="{ number: true, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseInput
                placeholder="Height"
                v-model="attribute.height"
                :validateError="errors[0]"
                :element="attribute.height"
                @input="attribute.height = $event"
              ></BaseInput>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side form__row_col--side-three"
              :rules="{ number: true, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseInput
                placeholder="Width"
                v-model="attribute.width"
                :validateError="errors[0]"
                :element="attribute.width"
                @input="attribute.width = $event"
              ></BaseInput>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side form__row_col--side-three"
              :rules="{ number: true, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseInput
                placeholder="Depth"
                v-model="attribute.depth"
                :validateError="errors[0]"
                :element="attribute.depth"
                @input="attribute.depth = $event"
              ></BaseInput>
            </ValidationProvider>
          </div>
        </template>
        <div class="form__row">
          <!-- <div class="form__row_col form__row_col--side"></div> -->
          <!-- <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Weight"
              v-model="art.weight"
              :validateError="errors[0]"
              :element="art.weight"
              @input="art.weight = $event"
            ></BaseInput>
          </ValidationProvider> -->
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            v-slot="{ errors }"
          >
            <BaseSelect
              placeholder="Art Type"
              :validateError="errors[0]"
              v-model="art.art_type_id"
              :selected="art.art_type_id"
              :items="artTypes"
              @input="art.art_type_id = $event.id"
            ></BaseSelect>
          </ValidationProvider>
        </div>
        <!-- <div class="form__row">
          <div class="form__row_col">
            <BaseSelect
              placeholder="Colors"
              :items="colors"
              @input="selectColor($event, 'add')"
            ></BaseSelect>
          </div>
        </div> -->
        <!-- <div class="form__row" style="margin-bottom: 0px">
          <div class="form__row_col form__row_col--flex">
            <Tag
              class="tag--capitalize"
              :class="{ 'tag--selected': i.selected }"
              v-for="i in art.colors"
              :key="i.id"
              :value="i.title"
              :close="true"
              @deleteTag="selectColor(i, 'remove')"
            ></Tag>
          </div>
        </div> -->
        <ValidationProvider tag="div" class="form__row" v-slot="{ errors }">
          <MultipleAutocompleteInput
            id="autocomplete-colors"
            :class="{ 'autocomplete-input--invalid': errors[0] }"
            v-model="art.colors"
            :focusOpen="true"
            :validateError="errors[0]"
            :loading="colorsLoading"
            :items="art.colors"
            :matches="colors"
            placeholder="Colors"
            @addItem="addColor"
            @input="art.colors = $event"
            @searchMatches="apiGetSearchedColors"
          ></MultipleAutocompleteInput>
        </ValidationProvider>

        <ValidationProvider tag="div" class="form__row" v-slot="{ errors }">
          <MultipleAutocompleteInput
            id="autocomplete-materials"
            :class="{ 'autocomplete-input--invalid': errors[0] }"
            v-model="art.materials"
            :focusOpen="true"
            :validateError="errors[0]"
            :loading="materialsLoading"
            :items="art.materials"
            :matches="materials"
            placeholder="Materials"
            @addItem="addMaterial"
            @input="art.materials = $event"
            @searchMatches="apiGetSearchedMaterials"
          ></MultipleAutocompleteInput>
        </ValidationProvider>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ min: 4, max: 4 }"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              :placeholder="art.is_range_years ? 'First Year' : 'Year'"
              :validateError="errors[0]"
              v-model="art.year"
              :element="art.year"
              @input="art.year = $event"
            ></BaseNumberInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side form__row_col--side-three">
            <SimpleToggle
              placeholder="Range of Years"
              v-model="art.is_range_years"
              :selected="+art.is_range_years"
              :items="[
                { id: '1', title: 'Active' },
                { id: '0', title: 'Inactive' },
              ]"
              @input="changeRangeYears"
            ></SimpleToggle>
          </div>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{
              less_then: art.year - 1,
              min: 4,
              max: 4,
            }"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Final Year"
              :disabled="!art.is_range_years"
              :validateError="errors[0]"
              v-model="art.last_year"
              :element="art.last_year"
              @input="art.last_year = $event"
            ></BaseNumberInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Images</h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label">Search Image</span>
            <DragAndDropImage
              class="uploader--full-width"
              id="search"
              size="15"
              :image="art.search_image"
              :v-model="art.search_image"
              @input="
                art.search_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                art.search_image = '';
              "
            ></DragAndDropImage>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label">
              Header Image
              <!-- <span class="form__row_label_required">*</span> -->
            </span>
            <DragAndDropImage
              class="uploader--full-width"
              id="header"
              size="15"
              :image="art.header_image"
              :v-model="art.header_image"
              @input="
                art.header_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                art.header_image = '';
              "
            ></DragAndDropImage>
          </div>
        </div>
        <div class="form__row form__row--bordered form__row--justify-start" style="padding: 8px">
          <span class="form__row_label"> Product Images </span>
          <DragAndDropImage
            style="margin: 4px"
            v-for="(i, idx) in art.product_images"
            :id="idx"
            :key="`image-${idx}`"
            :image="i"
            size="15"
            @input="art.product_images.splice(idx, 1, $event.image)"
            @removeImage="art.product_images.splice(idx, 1)"
          ></DragAndDropImage>
          <DragAndDropImage
            size="15"
            @input="art.product_images.push($event.image)"
            style="margin: 4px"
          ></DragAndDropImage>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Pricing</h3>
        <div class="form__row form__row--align-start">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ url: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Shop URL"
              :validateError="errors[0] || serverError.shop_url"
              v-model="art.shop_url"
              :element="art.shop_url"
              @input="art.shop_url = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  If available for sale on an external website, paste the link here.
                </span>
                <span class="form-control__simple-text">
                  URL:
                  <a class="form-control__simple-text" :href="art.shop_url" target="_blank">
                    {{ art.shop_url }}
                  </a>
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side-three"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Artist Price"
              symbol="$"
              :validateError="errors[0]"
              v-model="art.artist_price"
              :element="art.artist_price"
              @input="art.artist_price = $event"
            ></BaseNumberInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side-three"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Framing Cost"
              symbol="$"
              :validateError="errors[0]"
              v-model="art.cost"
              :element="art.cost"
              @input="art.cost = $event"
            ></BaseNumberInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side-three"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Duties"
              symbol="$"
              :validateError="errors[0]"
              v-model="art.duties"
              :element="art.duties"
              @input="art.duties = $event"
            ></BaseNumberInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side-three"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Retail Price"
              symbol="$"
              :disabled="!art.is_override_pricing"
              :validateError="errors[0]"
              v-model="art.price"
              :element="art.price"
              @input="art.price = $event"
            ></BaseNumberInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side form__row_col--side-three">
            <SimpleToggle
              placeholder="Override pricing"
              v-model="art.is_override_pricing"
              :selected="+art.is_override_pricing"
              @input="art.is_override_pricing = $event ? true : false"
            ></SimpleToggle>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--side-three"></div>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Associated Exhibition</h3>
        <ValidationProvider tag="div" class="form__row" v-slot="{ errors }">
          <MultipleAutocompleteInput
            id="autocomplete-exhibitions"
            :class="{ 'autocomplete-input--invalid': errors[0] }"
            v-model="art.exhibitions"
            info="Your artwork will only display on the most recently run exhibition."
            :focusOpen="true"
            :creating="false"
            :validateError="errors[0]"
            :loading="exhibitionsLoading"
            :items="art.exhibitions"
            :matches="exhibitions"
            placeholder="Selected Exhibitions"
            @input="art.exhibitions = $event"
            @searchMatches="apiGetExhibitionsList"
          ></MultipleAutocompleteInput>
        </ValidationProvider>
      </section>
      <section class="form">
        <h3 class="form__header">
          <span class="form__header-text">Inventory</span>
          <span class="form__header-text">
            {{ saleStatus[art.availability] ? saleStatus[art.availability].title : "" }}
          </span>
        </h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Condition"
              v-model="art.condition"
              :selected="art.condition"
              :items="conditionStatus"
              :close="true"
              @input="art.condition = $event.id"
            ></BaseSelect>
          </div>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Additional Condition Details"
              v-model="art.additional_condition_details"
              :element="art.additional_condition_details"
              :validateError="errors[0]"
              @input="art.additional_condition_details = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <BaseCheckbox
              class="checkbox--black"
              label="Resale"
              :icon="false"
              :checked="art.resale"
              @input="art.resale = $event"
            ></BaseCheckbox>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <BaseCheckbox
              class="checkbox--black"
              label="Signed"
              :icon="false"
              :checked="art.signed"
              @input="art.signed = $event"
            ></BaseCheckbox>
          </div>
        </div>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="Provenance"
            v-model="art.provenance"
            :element="art.provenance"
            :validateError="errors[0]"
            @input="art.provenance = $event"
          ></BaseTextarea>
        </ValidationProvider>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="Publications"
            v-model="art.publications"
            :element="art.publications"
            :validateError="errors[0]"
            @input="art.publications = $event"
          ></BaseTextarea>
        </ValidationProvider>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="Also Featured In"
            v-model="art.also_featured_in"
            :element="art.also_featured_in"
            :validateError="errors[0]"
            @input="art.also_featured_in = $event"
          ></BaseTextarea>
        </ValidationProvider>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <BaseTextarea
            placeholder="General Notes"
            v-model="art.general_notes"
            :element="art.general_notes"
            :validateError="errors[0]"
            @input="art.general_notes = $event"
          ></BaseTextarea>
        </ValidationProvider>
        <ValidationProvider
          v-if="show.on_hold"
          tag="div"
          class="form__row form__row_col"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Potential Buyer Name"
            v-model="art.recipient_name"
            :element="art.recipient_name"
            :validateError="errors[0]"
            @input="art.recipient_name = $event"
          />
        </ValidationProvider>
        <template v-if="show.loaned_on">
          <ValidationProvider
            tag="div"
            class="form__row form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Recipient Name"
              v-model="art.recipient_name"
              :element="art.recipient_name"
              :validateError="errors[0]"
              @input="art.recipient_name = $event"
            ></BaseInput>
          </ValidationProvider>
          <div class="form__row">
            <div class="form__row_col form__row_col--side">
              <DatePicker
                placeholder="Loaned on"
                format="MMM D, YYYY"
                :time="false"
                :validateError="serverError.loaned_on"
                :value="art.loaned_on"
                v-model="art.loaned_on"
                @input="art.loaned_on = $event"
              ></DatePicker>
            </div>
            <div class="form__row_col form__row_col--side">
              <DatePicker
                placeholder="Return by"
                format="MMM D, YYYY"
                :time="false"
                :validateError="serverError.return_by"
                :value="art.return_by"
                v-model="art.return_by"
                @input="art.return_by = $event"
              ></DatePicker>
            </div>
          </div>
        </template>
        <div class="form__row" v-if="show.inventory_date">
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="Date"
              format="MMM D, YYYY"
              :time="false"
              :validateError="serverError.inventory_date"
              :value="art.inventory_date"
              v-model="art.inventory_date"
              @input="art.inventory_date = $event"
            ></DatePicker>
          </div>
          <ValidationProvider
            v-if="show.recipient_name"
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Recipient Name"
              v-model="art.recipient_name"
              :element="art.recipient_name"
              :validateError="errors[0]"
              @input="art.recipient_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form" v-if="show.sales">
        <h3 class="form__header">
          <span class="form__header-text">Sales</span>
        </h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="Date"
              format="MMM D, YYYY"
              :time="false"
              :validateError="serverError.inventory_date"
              :value="art.inventory_date"
              v-model="art.inventory_date"
              @input="art.inventory_date = $event"
            ></DatePicker>
          </div>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Buyer Name"
              v-model="art.recipient_name"
              :element="art.recipient_name"
              :validateError="errors[0]"
              @input="art.recipient_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side-three"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Retail Price"
              symbol="$"
              :disabled="true"
              :validateError="errors[0]"
              v-model="art.price"
              :element="art.price"
              @input="art.price = $event"
            ></BaseNumberInput>
          </ValidationProvider>
          <div
            class="form__row_col form__row_col--side form__row_col--side-two form__row_col--flex"
          >
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side-two-one"
              :rules="{ more_then: 100 }"
              v-slot="{ errors }"
            >
              <BaseNumberInput
                class="form-control--rectangular-border-right"
                placeholder="Discount (Percentage)"
                :numberInteger="false"
                :startWithNull="true"
                :percentage="true"
                :disabled="disabled.discount_percent"
                :validateError="errors[0]"
                v-model="art.discount_percent"
                :element="art.discount_percent"
                @input="art.discount_percent = $event"
              ></BaseNumberInput>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side-two-one"
              v-slot="{ errors }"
            >
              <BaseNumberInput
                class="form-control--rectangular-border-left"
                placeholder="Discount (Price)"
                symbol="$"
                :numberInteger="false"
                :startWithNull="true"
                :disabled="disabled.discount"
                :validateError="errors[0]"
                v-model="art.discount"
                :element="art.discount"
                @input="art.discount = $event"
              ></BaseNumberInput>
            </ValidationProvider>
          </div>
        </div>
        <ValidationProvider
          tag="div"
          class="form__row form__row_col"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Invoice"
            v-model="art.invoice"
            :validateError="errors[0]"
            :element="art.invoice"
            @input="art.invoice = $event"
          ></BaseInput>
        </ValidationProvider>
      </section>
      <section class="form">
        <h3 class="form__header">
          <span class="form__header-text">Shipping</span>
        </h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Height"
              v-model="art.shipping_height"
              :validateError="errors[0]"
              :element="art.shipping_height"
              @input="art.shipping_height = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Width"
              v-model="art.shipping_width"
              :validateError="errors[0]"
              :element="art.shipping_width"
              @input="art.shipping_width = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side form__row_col--side-three"
            :rules="{ number: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Weight"
              v-model="art.shipping_weight"
              :validateError="errors[0]"
              :element="art.shipping_weight"
              @input="art.shipping_weight = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Acquired</h3>
        <div class="form__row">
          <div class="form__row_col">
            <BaseCheckbox
              class="checkbox--black"
              label="Artwork Acquired"
              :icon="false"
              :checked="art.is_acquired"
              @input="art.is_acquired = $event"
            ></BaseCheckbox>
          </div>
        </div>
        <ValidationProvider
          tag="div"
          class="form__row form__row_col"
          :rules="{ max: 255 }"
          v-slot="{ errors }"
        >
          <BaseInput
            placeholder="Acquired by (Name)"
            v-model="art.acquired_name"
            :element="art.acquired_name"
            :validateError="errors[0]"
            @input="art.acquired_name = $event"
          ></BaseInput>
        </ValidationProvider>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import Toggle from "@/components/inputs/Toggle.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import AutocompleteInput from "@/components/inputs/AutocompleteInput.vue";
import Tag from "@/components/Tag.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import SimpleToggle from "@/components/inputs/SimpleToggle.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import Info from "@/components/Info.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Editor from "@/components/inputs/Editor.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    DatePicker,
    BaseButton,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    DragAndDropImage,
    Toggle,
    AutocompleteInput,
    MultipleAutocompleteInput,
    BaseNumberInput,
    Tag,
    SimpleToggle,
    BaseCheckbox,
    Info,
    IconButton,
    IconPlus,
    IconClose,
    Editor,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      art: {
        title: "",
        artist: { id: null, title: "" },
        slug: "",
        availability: null,
        status: 0,
        featured: false,
        description: "",
        attributes: [
          {
            height: "",
            width: "",
            depth: "",
          },
        ],
        weight: "",
        art_type_id: null,
        colors: [],
        materials: [],
        year: "",
        is_range_years: false,
        last_year: "",
        search_image: "",
        header_image: "",
        product_images: [],
        sku: "",
        shop_url: "",
        artist_price: "",
        cost: "",
        duties: "",
        price: "",
        is_override_pricing: false,
        invoice: "",
        exhibitions: [],
        condition: null,
        additional_condition_details: "",
        resale: false,
        signed: false,
        provenance: "",
        publications: "",
        also_featured_in: "",
        general_notes: "",
        inventory_date: "",
        recipient_name: "",
        loaned_on: "",
        return_by: "",
        discount: "",
        discount_percent: "",
        shipping_height: "",
        shipping_width: "",
        shipping_weight: "",
        is_acquired: false,
        acquired_name: "",
      },
      artNotification: "",
      show: {
        inventory_date: false,
        recipient_name: false,
        discount: false,
        loaned_on: false,
        sales: false,
        on_hold: false,
      },
      disabled: {
        artStatus: false,
        discount: false,
        discount_percent: false,
      },
      artistTitle: "",
      removedImages: [],
      changed: false,
    };
  },
  created() {
    if (this.item != null) {
      const newItem = JSON.parse(JSON.stringify(this.item));
      const is_override_pricing =
        Number(newItem.price) !==
        Number(newItem.artist_price) * 2 + Number(newItem.cost) * 2 + Number(newItem.duties) * 2;
      this.art = {
        ...newItem,
        is_override_pricing,
      };
      if (!this.art.is_range_years) this.art.last_year = "";
      this.artistTitle = this.item.artist.title;
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("general", {
      colors: "getSearchedColors",
      colorsLoading: "getChangesColorsLoading",
      artTypes: "getArtTypes",
      materials: "getSearchedMaterials",
      materialsLoading: "getChangesMaterialsLoading",
      saleStatus: "getSaleStatus",
      conditionStatus: "getConditionStatus",
    }),
    ...mapGetters("artworks", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("artists", {
      artists: "getSearchedArtists",
      artistsLoading: "getChangesLoading",
    }),
    ...mapGetters("exhibitions", {
      exhibitions: "getItemsList",
      exhibitionsLoading: "getLoading",
    }),
  },
  watch: {
    art: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
          if (!val.status) {
            this.art.featured = false;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
    "art.availability": function (value) {
      const activeAvailability = this.saleStatus[value];
      const path = `${this.url}/artworks/${this.art.slug || "path"}`;
      switch (activeAvailability.title) {
        case "Donated": {
          this.artNotification = "This artwork is currently not published.";
          this.art.status = false;
          this.disabled.artStatus = true;
          this.show.inventory_date = true;
          this.show.recipient_name = true;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "For Sale": {
          this.artNotification = `This artwork is currently for sale at ${path}`;
          this.disabled.artStatus = false;
          this.show.inventory_date = false;
          this.show.recipient_name = false;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "For Sale (Not in Inventory)": {
          this.artNotification = `This artwork is currently for sale at ${path}`;
          this.disabled.artStatus = false;
          this.show.inventory_date = false;
          this.show.recipient_name = false;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "Gifted": {
          this.artNotification = "This artwork is currently not published.";
          this.art.status = false;
          this.disabled.artStatus = true;
          this.show.inventory_date = true;
          this.show.recipient_name = true;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "On Loan": {
          this.artNotification = `This artwork is currently for sale at ${path}`;
          this.disabled.artStatus = false;
          this.show.inventory_date = false;
          this.show.recipient_name = false;
          this.show.discount = false;
          this.show.loaned_on = true;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "Returned to Artist": {
          this.artNotification = "This artwork is currently not published.";
          this.art.status = false;
          this.disabled.artStatus = true;
          this.show.inventory_date = true;
          this.show.recipient_name = false;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "Sold": {
          this.artNotification = `This artwork is currently on display at ${path}`;
          this.disabled.artStatus = false;
          this.show.inventory_date = false;
          this.show.recipient_name = false;
          this.show.discount = true;
          this.show.loaned_on = false;
          this.show.sales = true;
          this.show.on_hold = false;
          break;
        }
        case "Unavailable": {
          this.artNotification = "This artwork is currently not published.";
          this.art.status = false;
          this.disabled.artStatus = true;
          this.show.inventory_date = false;
          this.show.recipient_name = false;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = false;
          break;
        }
        case "On Hold": {
          this.artNotification = `This artwork is currently for sale at ${path}`; // browngrottaarts.com/xxx
          this.disabled.artStatus = false;
          this.show.inventory_date = false;
          this.show.recipient_name = false;
          this.show.discount = false;
          this.show.loaned_on = false;
          this.show.sales = false;
          this.show.on_hold = true;
          break;
        }
      }
    },
    "art.artist_price": function () {
      this.calculationPrice();
    },
    "art.cost": function () {
      this.calculationPrice();
    },
    "art.duties": function () {
      this.calculationPrice();
    },
    "art.is_override_pricing": function (value) {
      if (!value) this.calculationPrice();
    },
    "art.discount": function () {
      if (!this.disabled.discount && this.art.discount) {
        this.art.discount_percent =
          (100 - (Number(this.art.discount) / Number(this.art.price)) * 100).toFixed(0) ?? "";
        this.disabled.discount_percent = true;
      }
      if (this.art.discount === "") {
        this.art.discount_percent = "";
        this.disabled.discount_percent = false;
      }
    },
    "art.discount_percent": function () {
      if (!this.disabled.discount_percent && this.art.discount_percent) {
        let discountSum = Number(this.art.price) * (Number(this.art.discount_percent) / 100);
        this.art.discount = (Number(this.art.price) - discountSum).toFixed(2) ?? "";
        this.disabled.discount = true;
      }
      if (this.art.discount_percent === "") {
        this.art.discount = "";
        this.disabled.discount = false;
      }
    },
  },
  methods: {
    ...mapActions("general", [
      "apiGetSearchedMaterials",
      "apiGetSearchedColors",
      "apiPostMaterial",
      "apiPostColor",
    ]),
    ...mapActions("artists", ["apiGetSearchedArtists"]),
    ...mapActions("exhibitions", ["apiGetExhibitionsList"]),
    ...mapMutations("artworks", ["setError"]),
    async addMaterial(val) {
      let data = { title: val };
      let result = await this.apiPostMaterial(data);
      if (result.success) {
        this.art.materials.push(result.item);
      } else {
        this.$toasted.show(result.error, {
          duration: 2000,
          type: "error",
        });
      }
    },
    async addColor(val) {
      let data = { title: val };
      let result = await this.apiPostColor(data);
      if (result.success) {
        this.art.colors.push(result.item);
      } else {
        this.$toasted.show(result.error, {
          duration: 2000,
          type: "error",
        });
      }
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid /* && this.art.product_images.length > 0 */
              ? this.saveArt()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      //this.$refs.observer.validate();
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    // async selectColor(item, type) {
    //   let selectedItems = this.art.colors;
    //   let newEl = true;

    //   switch (type) {
    //     case "add": {
    //       if (selectedItems.length) {
    //         let maxLength = 3;
    //         for (let el of selectedItems) {
    //           if (el.title === "Multi-color") {
    //             maxLength = 4;
    //           }
    //           if (+el.id === +item.id) {
    //             newEl = false;
    //             break;
    //           }
    //         }

    //         if (newEl && (this.art.colors.length < maxLength || item.title === "Multi-color")) {
    //           selectedItems.push(item);
    //         } else {
    //           this.$toasted.show("You can select up to 3 different colors + multi-color", {
    //             duration: 2000,
    //             type: "error",
    //           });
    //         }
    //       } else {
    //         selectedItems.push(item);
    //       }
    //       break;
    //     }
    //     case "remove": {
    //       selectedItems = selectedItems.filter(i => {
    //         return i.id != item.id;
    //       });
    //       break;
    //     }
    //   }
    //   this.art.colors = selectedItems;
    // },
    updateEditorText() {
      if (this.art.description) {
        const template = document.createElement("div");
        template.innerHTML = this.art.description;
        const links = Array.from(template.getElementsByTagName("a")) ?? [];
        links.forEach(elem => {
          elem.setAttribute("target", "_blank");
        });
        this.art.description = template.innerHTML;
      }
    },
    saveArt() {
      this.updateEditorText();
      let data = {
        ...this.art,
        materials: this.art.materials.map(el => {
          return el.id.toString();
        }),
        colors: this.art.colors.map(el => {
          return el.id.toString();
        }),
        exhibitions: this.art.exhibitions.map(el => {
          return el.id.toString();
        }),
      };
      if (this.art.id) {
        this.$emit("updateArt", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createArt", { data: data, imageForRemove: this.removedImages });
      }
    },
    changeRangeYears(event) {
      this.art.is_range_years = event;
      if (!this.art.is_range_years) this.art.last_year = "";
    },
    calculationPrice() {
      if (!this.art.is_override_pricing) {
        this.art.price =
          Number(this.art.artist_price) * 2 +
          Number(this.art.cost) * 2 +
          Number(this.art.duties) * 2;
      }
    },
    addArtAttribute() {
      this.art.attributes.push({
        height: "",
        width: "",
        depth: "",
      });
    },
    removeArtAttribute(index) {
      this.art.attributes.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.art {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
  &__piece-button {
    width: max-content;
    min-width: 3.4rem;
    padding: 0 10px;
  }
}
</style>

