<template>
  <div class="page">
    <div class="page__header" style="margin-bottom: 25px">
      <h1 style="margin-top: 10px">Collection</h1>
      <div class="page__header-search">
        <BaseSelect
          class="form-control--grey form-control--xs"
          placeholder="Search by"
          :selected="searchType"
          :items="searchTypes"
          @input="serachingType"
        ></BaseSelect>
        <BaseInput
          class="form-control--grey form-control--xs"
          style="max-width: 65rem"
          placeholder="Search"
          :icon="true"
          :timeout="true"
          :element="tableData.search"
          @input="searchItems"
        >
          <template #icon>
            <IconSearch color="#000" height="16" width="16"></IconSearch>
          </template>
        </BaseInput>
      </div>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <FilterBlock
          :items="filters"
          :matches="{ materials: materials }"
          :loadings="{ materials: materialsLoading }"
          @searchMatches="searchMatches"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
        ></FilterBlock>
        <ViewToggle @input="viewType = $event"></ViewToggle>
        <IconButton
          class="button--admin button--auto button--outline-grey button--ml-15"
          text="add art"
          @click="modal = true"
        >
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <div class="page__filters">
      <template v-for="filter in selectedFilters"
        ><Tag
          class="tag--capitalize"
          :class="{ 'tag--selected': i.selected }"
          v-for="i in filter.items"
          :key="i.title"
          :value="filter.key === 'price' ? `${i.title} - $${i.val}` : i.title"
          :close="true"
          :disabled="openFilters"
          @deleteTag="selectFilter({ key: filter.key, filter: i, val: false, apply: true })"
        ></Tag
      ></template>
    </div>
    <template v-if="viewType === 'list'">
      <Table
        :loading="loading"
        :items="items"
        :headerItems="headerItems"
        :tableData="tableData"
        :hasMorePages="hasMorePages"
        @sortTable="sortTable"
        @clickAction="clickAction"
        @loadMore="loadMore"
      ></Table>
    </template>
    <CardView
      v-else
      :items="items"
      @clickAction="clickAction"
      :hasMorePages="hasMorePages"
      @loadMore="loadMore"
    ></CardView>
    <Art
      v-if="modal"
      :modal="modal"
      :item="art"
      @hideModal="modal = false"
      @createArt="createArt"
      @updateArt="updateArt"
      @removeArt="confirmeRemoveArt"
      @updateTag="apiGetFiltersBlock(filterBlock)"
    ></Art>
  </div>
</template>
<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import FilterBlock from "@/components/Filter.vue";
import ViewToggle from "@/components/ViewToggle.vue";
import Tag from "@/components/Tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import CardView from "@/components/CardView.vue";
import Art from "./Art.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseInput,
    IconSearch,
    HorisontalTabsList,
    ViewToggle,
    FilterBlock,
    IconButton,
    IconPlusBold,
    Table,
    CardView,
    Tag,
    Art,
    BaseSelect,
  },
  data() {
    return {
      tabs: [
        {
          title: "Artworks",
          path: "/admin/collection/artworks",
          key: "artwork",
        },
        {
          title: "Artists",
          path: "/admin/collection/artists",
          key: "artists",
        },
        {
          title: "Publications",
          path: "/admin/collection/publications",
          key: "publications",
        },
      ],
      viewType: "list",
      modal: false,
      filterBlock: {
        list: "art_types,availabilities,materials,price,colors,regions",
        type: "collection",
      },
      openFilters: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        art_types: "",
        materials: "",
        price_start: "",
        price_end: "",
        colors: "",
        regions: "",
        search: "",
        search_type: "",
      },
      searchTypes: [
        {
          id: "title",
          title: "Title",
        },
        {
          id: "artist_name",
          title: "Artist",
        },
        {
          id: "sku",
          title: "SKU",
        },
      ],
      searchType: "title",
    };
  },
  async created() {
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetArtworks(this.tableData);
    this.initialArt();
    await this.apiGetColors();
    await this.apiGetArtTypes();
    await this.apiGetExhibitionsList({});
  },
  computed: {
    ...mapGetters("general", {
      materials: "getSearchedMaterials",
      materialsLoading: "getChangesMaterialsLoading",
    }),
    ...mapGetters("filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
    ...mapGetters("artworks", {
      items: "getArtworks",
      headerItems: "getArtworksHeaders",
      art: "getArt",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialArt();
        this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("general", [
      "apiDeleteUploadImage",
      "apiGetColors",
      "apiGetArtTypes",
      "apiGetSearchedMaterials",
    ]),
    ...mapActions("filters", ["apiGetFiltersBlock"]),
    ...mapMutations("filters", ["updateFilters", "updateSelectedFilters", "clear"]),

    ...mapActions("artworks", [
      "apiGetArtworks",
      "apiGetArt",
      "apiPostArt",
      "apiPutArt",
      "apiDeleteArt",
    ]),

    ...mapActions("exhibitions", ["apiGetExhibitionsList"]),
    ...mapMutations("artworks", ["initialArt", "setError"]),
    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async searchMatches({ key, event }) {
      switch (key) {
        case "materials": {
          await this.apiGetSearchedMaterials(event);
          break;
        }
      }
    },
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetArtworks(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    serachingType(value) {
      this.searchType = value.id;
      this.searchItems(this.tableData.search);
    },
    async searchItems(val) {
      if (this.tableData.search != val || this.tableData.search_type != this.searchType) {
        let data = { ...this.tableData, page: 1, search: val, search_type: this.searchType };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.tableData, page: 1 };
      this.selectedFilters.forEach(el => {
        if (el.key === "price") {
          data.price_start = "";
          data.price_end = "";
          el.items.forEach(i => {
            data[i.key] = i.val;
          });
        } else {
          let filters = el.items.map(i => {
            return i.id;
          });
          data[el.key] = filters.join(",");
        }
      });
      let success = await this.apiGetArtworks(data);
      if (success) {
        this.tableData = data;
      }
    },
    async editArt({ id }) {
      let result = await this.apiGetArt(id);
      if (result) {
        this.modal = true;
      }
    },

    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetArt(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmeRemoveArt(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "view": {
          if (item.status !== "inactive") this.$router.push({ path: `/artworks/${item.slug}` });
          break;
        }
      }
    },

    async createArt({ data, imageForRemove }) {
      let result = await this.apiPostArt(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
    async updateArt({ data, imageForRemove }) {
      let result = await this.apiPutArt(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
    confirmeRemoveArt(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeArt(item.id);
        }
      });
    },
    async removeArt(id) {
      let result = await this.apiDeleteArt(id);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
  },
};
</script>
